html {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 32pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container_logo {
  width: 160pt;
  height: 100%;
}

.logo {
  width: 100%;
  height: 100%;
}

.mainPanel {
  display: flex;
  flex-direction: column;
}

.products {
}

.addProduct {
}

.addProductForm {
  display: flex;
  flex-direction: column;
}

.addProductField {
  padding: 10pt 10pt;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.addProductFieldLabel {
}

.addProductFieldSet {
  width: 90%;
  margin-top: 10pt;
  margin-bottom: 10pt;
}

.input_text {
  height: 30pt;
}

.input_number {
  height: 30pt;
}

.input_select {
  height: 36pt;
}

.container_button_addProduct {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 16pt;
  padding-bottom: 16pt;
}

.button_addProduct {
  width: 96pt;
  height: 28pt;
  background-color: chartreuse;
}

@media only screen and (min-width: 768px) {
  .header {
    width: 100%;
    height: 48px;
  }

  .container_logo {
    width: 240px;
    height: 100%;
  }

  .logo {
    width: 100%;
    height: 100%;
  }
  .mainPanel {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .products {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .addProduct {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .addProductForm {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .addProductField {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 600px;
  }

  .addProductFieldLabel {
  }

  .addProductFieldSet {
    width: 90%;
    max-width: 600px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .input_text {
    height: 30px;
  }

  .input_number {
    height: 30px;
  }

  .input_select {
    height: 36px;
  }

  .container_button_addProduct {
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .button_addProduct {
    width: 108px;
    height: 48px;
    background-color: chartreuse;
  }
}
